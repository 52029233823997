<template>
    <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            class="text-nowrap"
            style="min-height : 235px"
    >
        <template #cell(coin)="data">
            <b-media vertical-align="center">
                <template #aside>
                    <b-avatar
                            size="32"
                            :src="`/coins/${data.item.coin}.png`"
                            :variant="`light-info`"
                    />
                </template>
                <b-link
                        class="font-weight-bold d-block text-nowrap"
                >
                    {{ $coins[data.item.coin].persianName }}
                </b-link>
                <small class="text-muted">{{ $coins[data.item.coin].code }}</small>
            </b-media>
        </template>

        <template #cell(balance)="{item}">
            <span>
                {{$toLocal(item.balance) || 0}}
            </span>
        </template>
        <template #cell(withdrawalFee)="{item}">
            <CryptoCurrencyField :coin=coin :item="item" target="withdrawalFee" label="کارمزد برداشت"/>
        </template>
        <template #cell(minWithdraw)="{item}">
            <CryptoCurrencyField :coin=coin :item="item" target="minWithdraw" label="حداقل برداشت"/>
        </template>
        <template #cell(maxWithdraw)="{item}">
            <CryptoCurrencyField :coin=coin :item="item" target="maxWithdraw" label="حداکثر برداشت"/>
        </template>
        <template #cell(minAmount)="{item}">
            <CryptoCurrencyField :item="item" target="minAmount" label="حداقل سفارش"/>
        </template>
        <template #cell(maxAmount)="{item}">
            <CryptoCurrencyField :item="item" target="maxAmount" label="حداکثر سفارش"/>
        </template>
        <template #cell(maxAmountExchange)="{item}">
            <CryptoCurrencyField :item="item" target="maxAmountExchange" label="حداکثر سفارش"/>
        </template>
        <template #cell(minAmountExchange)="{item}">
            <CryptoCurrencyField :item="item" target="minAmountExchange" label="حداکثر سفارش"/>
        </template>
        <template #cell(creditCardFee)="{item}">
            <CryptoCurrencyField :item="item" target="creditCardFee" label="کارمزد کارت ها"/>
        </template>

        <template #cell(tokenTypeDetails)="{item}">
            <b-button @click="$emit('click',item)" variant="light-primary">
                <feather-icon
                        icon="LayersIcon"
                        size="16"
                        class="align-middle text-body"
                />
            </b-button>
        </template>


    </b-table>
</template>

<script>
    import {
        BAvatar,
        BLink,
        BMedia,
        BButton,
        BTable
    } from "bootstrap-vue";
    import CryptoCurrencyField from "@/views/CryptoCurrency/CryptoCurrencyTableField";

    export default {
        name: "CryptoCurrencyTable",
        props: ['items', 'columns','coin'],
        components: {
            CryptoCurrencyField,
            BTable,
            BMedia,
            BAvatar,
            BButton,
            BLink,
        },
        data() {
            return {
                dir: false,
                loading: false,
                edit: false,
                type: false
            }
        },
        methods: {
            dataEntered(coin, e) {
                console.log(coin, e)
                this.edit = false
                // this.data[coin] = this.$S2N(this.$toLocal(e)) || 0
                // this.$forceUpdate()
            },
        }
    }
</script>

<style scoped>

</style>